import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as SourceActions from '../actions/sources';
import { toggleSidebar } from '../actions/app';
import { push } from 'connected-react-router'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AceEditor from 'react-ace';
import { isJsonValid } from '../utils/objectUtils';
import { getDebuggerUrl } from '../utils/misc';

function getDefaultBody(domain='') {
  return `{
  "product": {
    "name": "${domain}",
    "price": -1,
    "country": "",
    "currency": "",
    "reference": "",
    "productIdentifiers": {
      "brand": "",
      "categoryPath": []
    }
  },
  "webhook": {
    "url": "https://webhook.site/aa9a5cf4-54b6-4ed2-9241-1bdadf594f02"
  }
}`
}

function mapStateToProps(state) {
  return {
    ...state.sources,
    sidebarOpen: state.app.sidebarOpen,
    isFetchingConfigs: state.configurations.isFetchingConfigs,
    sourceConfigurations: state.configurations.sourceConfigurations,
    environment: state.app.environment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...SourceActions, push, toggleSidebar }, dispatch),
  }
}


const styles = (theme) => ({
  root: {
    // display: 'flex',
    margin: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class SourceSidebar extends Component {
  static propTypes = {
    selectedSource: PropTypes.object,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      push: PropTypes.func,
      toggleSidebar: PropTypes.func.isRequired,
      resolveProduct: PropTypes.func.isRequired,
    }),
    sourceConfigurations: PropTypes.array.isRequired,
    isFetchingConfigs: PropTypes.bool.isRequired,
    isRunningSource: PropTypes.bool.isRequired,
    resolveProductResponse: PropTypes.object,
    environment: PropTypes.string,
  }

  constructor(props) {
    super(props);
    console.log(props.selectedSource);
    this.state = {
      isRunPanelOpen: false,
      runPromptText: getDefaultBody(props.selectedSource && props.selectedSource.domain),
      showingResponse: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resolveProductResponse !== this.props.resolveProductResponse && this.props.resolveProductResponse) {
      this.setState({ responseText: JSON.stringify(this.props.resolveProductResponse, null, 2), showingResponse: true })
    }
  }

  onViewConfigurations = () => {
    this.props.actions.toggleSidebar(false)
    this.props.actions.push(`/sources/${this.props.selectedSource.guid}/configurations`)
  }

  isRunDisabled() {
    return !isJsonValid(this.state.runPromptText)
  }

  runConfig = () => {
    const { runPromptText } = this.state;
    const { selectedSource } = this.props;
    const body = JSON.parse(runPromptText)
    this.props.actions.resolveProduct(selectedSource.guid, body);
  }

  render() {
    const { classes, selectedSource, isFetchingConfigs, sourceConfigurations, isRunningSource } = this.props;
    const { isRunPanelOpen, runPromptText, showingResponse, responseText } = this.state;
    if (!selectedSource) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }

    let taskId;
    try {
      const respObj = JSON.parse(responseText);
      taskId = respObj.guid;
    } catch (e) {}

    let runPanel = (
      <React.Fragment>
        <AceEditor
          style={{ height: 300, width: 468 }}
          mode="json"
          theme="terminal"
          name="runPrompt"
          key="runPrompt"
          onChange={(runPromptText) => this.setState({ runPromptText })}
          editorProps={{ $blockScrolling: Infinity }}
          fontSize="12px"
          highlightActiveLine
          showGutter={false}
          value={(showingResponse ? responseText : runPromptText) || ''.toString()}/>
        <div style={{ display: 'flex' }}>
          <Button variant="outlined" 
            className={classes.button} 
            onClick={() => this.setState({ isRunPanelOpen: false })}>
                    Cancel
          </Button>
          {showingResponse ? (
            <Button variant="outlined" 
              className={classes.button} 
              onClick={() => this.setState({ showingResponse: false })}>
                   Reset
            </Button>
          ) : (
            <Button variant="outlined" 
              disabled={this.isRunDisabled()} 
              className={classes.button} 
              onClick={this.runConfig}>
                   Run
            </Button>
          )}
         
        </div>
      </React.Fragment>
    )

    if (isRunningSource) {
      runPanel = (
        <div style={{ height: 300, width: 400 }} className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      )
    }


    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          {selectedSource.domain}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {selectedSource.guid}
        </Typography>
        {!isFetchingConfigs && (
          <Typography variant="overline" gutterBottom>
            {sourceConfigurations.length} configurations, {sourceConfigurations.filter(c => c.status === 'ACTIVE').length} active
          </Typography>
        )}
        <Button variant="outlined" className={classes.button} onClick={this.onViewConfigurations}>
          View Configurations
        </Button>
        {taskId && isRunPanelOpen &&
          <Button
            variant="outlined"
            href={getDebuggerUrl(taskId, this.props.environment, true)}
            target="_blank">
            Debug Run
          </Button>}
        {!!sourceConfigurations.length && (
          <div>
            {isRunPanelOpen ? runPanel : (
              <Button variant="outlined" 
                className={classes.button} 
                onClick={() => this.setState({ isRunPanelOpen: true })}>
                   Run
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
}

const componentWithStyles =  withStyles(styles)(SourceSidebar);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(componentWithStyles)
