import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ConfigActions from '../actions/configurations';
import { toggleSidebar } from '../actions/app';
import { push } from 'connected-react-router'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { cloneDeep } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';


function mapStateToProps(state) {
  return {
    ...state.configurations,
    selectedSource: state.sources.selectedSource,
    sidebarOpen: state.app.sidebarOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...ConfigActions, push, toggleSidebar }, dispatch),
  }
}


const styles = (theme) => ({
  root: {
    // display: 'flex',
    margin: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
  },
});

class ConfigSidebar extends Component {
  static propTypes = {
    selectedSource: PropTypes.object,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      push: PropTypes.func,
      toggleSidebar: PropTypes.func,
      updateConfigStatus: PropTypes.func,
      updateSourceConfigProperties: PropTypes.func.isRequired,
      updateSourceConfigAssociation: PropTypes.func.isRequired,
    }),
    selectedConfig: PropTypes.object,
    isFetchingSelectedConfig: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedConfig: null,
      newRcGuid: '',
      editingRcName: '',
    }
  }

  componentDidMount() {
    if (this.props.selectedConfig) {
      this.setState({ selectedConfig: this.props.selectedConfig })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedConfig !== this.props.selectedConfig) {
      this.setState({ selectedConfig: this.props.selectedConfig })
    }
  }

  onSaveClick = () => {
    const { selectedConfig } = this.state;
    this.props.actions.updateConfigStatus(selectedConfig.guid, selectedConfig.status);
  }

  handleStatusChange = (event) => {
    const { selectedConfig } = cloneDeep(this.state);
    selectedConfig.status = event.target.value;
    this.setState({ selectedConfig });
  }

  isSaveDisabled() {
    const { selectedConfig } = this.props;
    return selectedConfig.status === this.state.selectedConfig.status;
  }

  selectRC(name, runtimeConfigGuid) {
    const { selectedConfig, selectedSource } = this.props;
    this.props.actions.push(`/sources/${selectedSource.guid}/configurations/${selectedConfig.guid}/runtimeConfig/${runtimeConfigGuid}`)
  }

  handleRcChange = (e) => {
    this.setState({ newRcGuid: e.target.value })
  }

  onEditRcClick = (name) => {
    this.setState({ editingRcName: name, newRcGuid: this.state.selectedConfig.properties[name] })
  }
  
  onSaveRcClick = () => {
    const { newRcGuid, editingRcName, selectedConfig } = cloneDeep(this.state);
    // don't check for changes in order to allow re-save values 
    selectedConfig.properties[editingRcName] = newRcGuid;
    // keep updating old source 
    this.props.actions.updateSourceConfigProperties(selectedConfig.guid, selectedConfig.properties);
    // update associations on new source
    this.props.actions.updateSourceConfigAssociation(selectedConfig).catch(err => {
      const result = err.message.match(/"code":(?<code>\d+),"message":"(?<message>.+?)"/)
      alert(`Cannot update associations.\n\nError: ${result.groups['code']} Message: ${result.groups['message']}`)
    })
    this.setState({ newRcGuid: '', editingRcName: '' })
  }

  render() {
    const { classes, selectedSource, isFetchingSelectedConfig } = this.props;
    const { selectedConfig, editingRcName, newRcGuid } = this.state;

    if (!selectedSource || !selectedConfig || isFetchingSelectedConfig) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }
    const properties = selectedConfig.properties || {}

    const editingRcInput = (
      <div>
        <TextField
          autoFocus
          id="rc-editor"
          label={editingRcName}
          className={classes.textField}
          value={newRcGuid}
          onChange={this.handleRcChange}
          margin="normal"
        />
        <Button variant="outlined" className={classes.button} onClick={this.onSaveRcClick}>
          Save
        </Button>
      </div>
      
    )

    return (
      <div className={classes.root}>
        <Typography variant="h5" gutterBottom>
          Source Configuration
        </Typography>
        <Typography variant="h6" gutterBottom>
          {selectedConfig.guid}
        </Typography>
        {!!selectedConfig.countryCode ? 
          <Typography variant="h6" gutterBottom>
            Country: {selectedConfig.countryCode}
          </Typography> :
          <Typography variant="h6" gutterBottom>
            Country: Default
          </Typography>
        }
        
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="age-label-placeholder">
            Status
            </InputLabel>
            <Select
              value={selectedConfig.status}
              onChange={this.handleStatusChange}
              input={<Input name="status" id="satus-label-placeholder" />}
              displayEmpty
              name="status"
              className={classes.selectEmpty}
            >
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="PROVISIONING">PROVISIONING</MenuItem>
              <MenuItem value="IN_REVIEW">IN_REVIEW</MenuItem>
              <MenuItem value="ERROR">ERROR</MenuItem>
              <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined" disabled={this.isSaveDisabled()} className={classes.button} onClick={this.onSaveClick}>
          Save
          </Button>
        </div>
        <div>
          <List className={classes.list}>
            {editingRcName === 'GetProductFromUrlRC' ? editingRcInput : (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <ListItem button onClick={() => this.selectRC('GetProductFromUrlRC', properties.GetProductFromUrlRC)}>
                  <ListItemText
                    primary="GetProductFromUrlRC"
                    secondary={
                      <Typography component="span" className={classes.inline} color="textPrimary">
                        {properties.GetProductFromUrlRC}
                      </Typography>
                    }
                  />
                </ListItem>
                <IconButton onClick={() => this.onEditRcClick('GetProductFromUrlRC')}>
                  <EditIcon />
                </IconButton>
              </div>
            )}

            {editingRcName === 'GetProductsFromSearchRC' ? editingRcInput : (
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <ListItem button onClick={() => this.selectRC('GetProductsFromSearchRC', properties.GetProductsFromSearchRC)}>
                  <ListItemText
                    primary="GetProductsFromSearchRC"
                    secondary={
                      <Typography component="span" className={classes.inline} color="textPrimary">
                        {properties.GetProductsFromSearchRC}
                      </Typography>
                    }
                  />
                </ListItem>
                <IconButton onClick={() => this.onEditRcClick('GetProductsFromSearchRC')}>
                  <EditIcon />
                </IconButton>
              </div>
            )}
            
            
          </List>
        </div>
        
      </div>
    );
  }
}

const componentWithStyles =  withStyles(styles)(ConfigSidebar);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(componentWithStyles)
