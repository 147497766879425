import { fetchSourceConfigurations, propertiesFromAssociations } from '../utils/api';
import { getConfigGuid } from '../selectors/router';
import jssdk from '../utils/jssdk'

export const FETCHING_SOURCE_CONFIGS = 'FETCHING_SOURCE_CONFIGS'
export const RECEIVED_SOURCE_CONFIGS = 'RECEIVED_SOURCE_CONFIGS'
export const SELECT_SOURCE_CONFIG = 'SELECT_SOURCE_CONFIG'
export const FETCHING_SOURCE_CONFIG = 'FETCHING_SOURCE_CONFIG'
export const SELECT_RUNTIME_CONFIG = 'SELECT_RUNTIME_CONFIG'
export const SELECT_RUNTIME_GONFIG_GUID = 'SELECT_RUNTIME_GONFIG_GUID'

export const selectSourceConfig = (selectedConfig) => ({ type: SELECT_SOURCE_CONFIG, selectedConfig })
export const selectRuntimeConfig = (selectedRC) => ({ type: SELECT_RUNTIME_CONFIG, selectedRC })
export const selectRCGuid = (rcGuid) => ({ type: SELECT_RUNTIME_GONFIG_GUID, rcGuid })
export const fetchingSourceConfig = () => ({ type: FETCHING_SOURCE_CONFIG })

export function getConfigsForSource(commerceSourceId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_SOURCE_CONFIGS })
      const sourceConfigurations = await fetchSourceConfigurations(commerceSourceId) 
      dispatch({ type: RECEIVED_SOURCE_CONFIGS, sourceConfigurations })

      const state = getState();
      const selectedConfigMatch = getConfigGuid(state);
      const selectedConfigGuid = !!selectedConfigMatch && selectedConfigMatch.params.configGuid;
      if (!selectedConfigGuid) return;

      const selectedConfig = sourceConfigurations.find(s => s.guid === selectedConfigGuid);
      if (!selectedConfig) return;

      dispatch(selectSourceConfig(selectedConfig))
    } catch (e) {
      console.error('Error fetching commerce sources', e)
      dispatch({ type: RECEIVED_SOURCE_CONFIGS, sourceConfigurations: [] })
    }
  }
}

export function getSourceConfig(sourceConfigId) {
  return async (dispatch, getState) => {
    try {
      if (!getState().configurations.isFetchingSelectedConfig) dispatch(fetchingSourceConfig())
      // keep reading from properties 
      const sourceConfiguration = await jssdk.commerceConfiguration.get(sourceConfigId);
      // replace properties with association
      sourceConfiguration.properties = await propertiesFromAssociations(sourceConfiguration)
      dispatch(selectSourceConfig(sourceConfiguration))
    } catch (e) {
      console.error('Error updating config status', e);
      throw e;
    }
  }
}

export function updateConfigStatus(sourceConfigId, status) {
  return async dispatch => {
    try {
      dispatch(fetchingSourceConfig())
      await jssdk.commerce.updateSourceConfigStatus(sourceConfigId, status);
      dispatch(getSourceConfig(sourceConfigId))
    } catch (e) {
      console.error('Error updating config status', e);
      throw e;
    }
  }
}

export function updateSourceConfigAssociation(source) {
  return async dispatch => {
    try {
      dispatch(fetchingSourceConfig())
      await jssdk.commerce.setAssociation({
        associationType: 'extraction', 
        sourceConfigurationId: source.guid, 
        index: 0, 
        runtimeConfigurationId: source.properties.GetProductsFromSearchRC,
      });
      // by requirement update both associations even if only one of them was changed
      await jssdk.commerce.setAssociation({
        associationType: 'extraction', 
        sourceConfigurationId: source.guid, 
        index: 1, 
        runtimeConfigurationId: source.properties.GetProductFromUrlRC,
      });
      dispatch(getSourceConfig(source.guid))
    } catch (e) {
      console.error('Error updating config association', e);
      throw e;
    }
  }
}

export function updateSourceConfigProperties(sourceConfigId, properties) {
  return async dispatch => {
    try {
      dispatch(fetchingSourceConfig())
      await jssdk.commerce.updateSourceConfigProperties(sourceConfigId, properties);
      dispatch(getSourceConfig(sourceConfigId))
    } catch (e) {
      console.error('Error updating config properties', e);
      throw e;
    }
  }
}