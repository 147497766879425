import { put, takeEvery } from 'redux-saga/effects';
import { APP_LOADED_SAGA } from '../actions/app';
import { getCurrentUser } from '../actions/user';
import { getCommerceSources } from '../actions/sources';

function *appLoaded() {
  yield put(getCurrentUser())
  yield put(getCommerceSources())
}

export default [
  takeEvery(APP_LOADED_SAGA, appLoaded),
]