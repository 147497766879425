export function autobind(obj) {
  const prototypeKeys = Object.getOwnPropertyNames(Object.getPrototypeOf(obj));
  prototypeKeys.forEach((key) => {
    if (key !== 'constructor' && typeof (obj[key]) === 'function') {
      obj[key] = obj[key].bind(obj);
    }
  });
}


export function isJsonValid(json) {
  try {
    return !!JSON.parse(json);
  } catch (e) {
    return false;
  }
}
