import 'babel-polyfill';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import store from './store/configureStore'
import AppRoutes from './routes'
import { Provider } from 'react-redux'
// import * as serviceWorker from './serviceWorker'
import UserPreferences from './utils/userPreferences'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import 'brace/mode/json'
import 'brace/theme/terminal'
import 'brace/ext/language_tools'
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/theme-material.css';
import 'ag-grid/dist/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise/main';
LicenseManager.setLicenseKey('ag-Grid_Evaluation_License_Key_Not_for_Production_100Devs26_March_2018__MTUyMjAxODgwMDAwMA==e8f8bbc1ff5aff3ac920e42d0542b6c9');

UserPreferences.configure('merchant-ui-preferences', {

})

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  palette: {
    primary: {
      main: '#357b8e',
    },
    secondary: {
      main: '#CB2257',
    },
    // type: 'dark',
  },
})


const render = () => {
  ReactDOM.render((
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <AppRoutes />
      </MuiThemeProvider>
    </Provider>
  ), document.getElementById('root'))
}

render()
if (module.hot) {
  module.hot.accept('./components/App', () => {
    render()
  })
}
