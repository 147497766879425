import { takeEvery, put } from 'redux-saga/effects';
import * as ConfigActions from '../actions/configurations';
import { fetchRuntimeConfig } from '../utils/api';

function *runtimeConfigSelected({ rcGuid }) {
  const selectedRc = yield fetchRuntimeConfig(rcGuid)
  yield put(ConfigActions.selectRuntimeConfig(selectedRc))
}

export default [
  takeEvery(ConfigActions.SELECT_RUNTIME_GONFIG_GUID, runtimeConfigSelected),
]