import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../import-logo-primary.png';
import Sidebar from './Sidebar';
import { DRAWER_WIDTH } from '../utils/constants';
import { hot } from 'react-hot-loader';
import { bindActionCreators } from 'redux'
import { appLoadedSaga, toggleSidebar } from '../actions/app';
import { connect } from 'react-redux'
import { autobind } from '../utils/objectUtils';
import EnvironmentPicker from './EnvironmentPicker';
import Sources from './Sources';
import { Route, Redirect, Switch } from 'react-router';
import SourceConfigs from './SourceConfigs';


function mapStateToProps(state) {
  return {
    user: state.user,
    sidebarOpen: state.app.sidebarOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        appLoadedSaga,
        toggleSidebar,
      }, 
      dispatch
    ),
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 24,
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginRight: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
    position: 'absolute',
    right: 15,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
});


class App extends React.PureComponent {
  
  static propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      appLoadedSaga: PropTypes.func.isRequired,
      toggleSidebar: PropTypes.func.isRequired,
    }),
    sidebarOpen: PropTypes.bool.isRequired,
  };

  state = {
    snackbarOpen: false,
    snackbarMessage: '',
    snackbarHideDuration: undefined,
    snackbarAction: [],
  };

  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.props.actions.appLoadedSaga()
  }

  handleSnackbarClose() {
    this.setState({ snackbarOpen: false, snackbarHideDuration: undefined });
  }

  showSnackbar({
    message = '',
    duration,
    position = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    actions = [],
  }) {
    if (!duration) actions.push(( 
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={this.props.classes.close}
        onClick={this.handleSnackbarClose}
      >
        <CloseIcon />
      </IconButton>
    ))
    return this.setState({ 
      snackbarOpen: true, 
      snackbarMessage: message, 
      snackbarHideDuration: duration,
      snackbarPosition: position,
      snackbarAction: actions,
    })
  }

  render() {
    const { classes, sidebarOpen } = this.props;
    const { 
      snackbarHideDuration, 
      snackbarMessage, 
      snackbarOpen, 
      snackbarPosition, 
      snackbarAction,
    } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(classes.appBar, sidebarOpen && classes.appBarShift)}>
            <Toolbar disableGutters={!sidebarOpen} className={classes.toolbar}>
              <Typography
                component="h1"
                color="inherit"
                noWrap
                className={classes.title}>
                <img src={Logo} className="App-logo" alt="logo" />
              </Typography>
              <EnvironmentPicker />
              {/* <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  open && classes.menuButtonHidden,
                )}>
                <MenuIcon />
              </IconButton> */}
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/sources" component={Sources} />} />
              <Route path="/sources/:sourceGuid/configurations/:configGuid" component={SourceConfigs} />
              <Route path="/sources/:sourceGuid/configurations" component={SourceConfigs} />
              <Route path="/sources/:sourceGuid" component={Sources} />
              <Route path="/sources" component={Sources} />
            </Switch>
          </main>
          <Sidebar
            isOpen={sidebarOpen} 
            onOpen={() => this.props.actions.toggleSidebar(true)} 
            onClose={() => this.props.actions.toggleSidebar(false)} />
        </div>
        <Snackbar
          anchorOrigin={snackbarPosition}
          open={snackbarOpen}
          onClose={this.handleSnackbarClose}
          message={snackbarMessage}
          autoHideDuration={snackbarHideDuration}
          action={snackbarAction}
        />
      </React.Fragment>
    );
  }
}


const componentWithStyles = withStyles(styles)(App); 

const container =  connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(componentWithStyles)

export default hot(module)(container);