import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ConfigActions from '../actions/configurations';
import { getCommerceSources } from '../actions/sources';
import { toggleSidebar } from '../actions/app';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router'
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



function mapStateToProps(state) {
  return {
    ...state.configurations,
    selectedSource: state.sources.selectedSource,
    sidebarOpen: state.app.sidebarOpen,
    isFetchingSources: state.sources.isFetchingSources,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...ConfigActions, toggleSidebar, push, getCommerceSources }, dispatch),
  }
}

const styles = theme => ({
  root: { 
    display: 'flex',
    justifyContent: 'center', 
    height: 'calc(100vh - 175px)', 
    width: '100%',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  input: {
    margin: theme.spacing.unit,
  },
  flexCenter: { display: 'flex', margin: `${theme.spacing.unit * 2} auto` },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    margin: theme.spacing.unit * 2,
  },
});

class SourceConfigs extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isFetchingConfigs: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      selectSourceConfig: PropTypes.func.isRequired,
      toggleSidebar: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }),
    sidebarOpen: PropTypes.bool.isRequired,
    sourceConfigurations: PropTypes.array.isRequired,
    selectedSource: PropTypes.object,
    isFetchingSources: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      
      rowData: [],
    }
  }

  componentDidMount() {
    this.setUpGrid()
    if (this.props.match.params.configGuid) {
      this.props.actions.toggleSidebar(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceConfigurations.length !== this.props.sourceConfigurations.length) {
      this.setUpGrid()
    }
    if (prevProps.sidebarOpen && !this.props.sidebarOpen && this.gridApi) {
      this.gridApi.deselectAll()
    }
  }

  static columnDefs = [
    { headerName: 'Id', field: 'guid', width: 300 },
    // { headerName: 'Domain', field: 'domain' },
    { headerName: 'Status', field: 'status', width: 200 },
    { headerName: 'Country Code', field: 'countryCode', width: 200 },
  ]

  setUpGrid() {
    const sourceConfigurations = [...this.props.sourceConfigurations]
    sourceConfigurations.forEach(source => {
      if (!source.countryCode) {
        source.countryCode = 'DEFAULT'
      }
    })
    this.setState({ rowData: sourceConfigurations })
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  onRowSelected = () => {
    const { selectedSource } = this.props;
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (!selectedRow) return;

    this.props.actions.selectSourceConfig(selectedRow)
    if (!this.props.sidebarOpen) this.props.actions.toggleSidebar(true);
    this.props.actions.push(`/sources/${selectedSource.guid}/configurations/${selectedRow.guid}`)
  }

  onBackClick = () => {
    this.props.actions.toggleSidebar(false);
    this.props.actions.push('/sources')
  }

  render() {
    const { isFetchingConfigs, classes, selectedSource, isFetchingSources } = this.props;
    return (
      <div>
        {selectedSource && (
          <div className={classes.flexCenter} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <div className={classes.backButton}>
              <IconButton onClick={this.onBackClick}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src="/Pointy.png" style={{ height: 60, width: 60 }} />
              <Typography variant="h4" gutterBottom>
                {selectedSource.domain} Configurations
              </Typography>
            </div>
            <div />
          </div>
        )}
        
        <div className={classes.root}>
        
          {(() => {
            if (isFetchingConfigs || isFetchingSources) {
              return (
                <div className={classes.loaderContainer}>
                  <CircularProgress className={classes.progress} />
                </div>
              
              );
            }
            return ( 
              <div 
                className="ag-theme-material"
                style={{ height: 'calc(100vh - 220px)',  width: '100%', maxHeight: '90%', maxWidth: 700 }} >
              
                <div className={classes.flexCenter}>
                  <Input
                    fullWidth
                    placeholder="Search"
                    className={classes.input}
                    onChange={(e) => {
                      if (this.gridApi) this.gridApi.setQuickFilter(e.target.value)
                    }}
                    inputProps={{
                      'aria-label': 'Search',
                    }}/>
                </div>
              
                <AgGridReact
                  enableSorting
                  enableFilter
                  enableColResize
                  onGridReady={this.onGridReady}
                  columnDefs={SourceConfigs.columnDefs}
                  rowData={this.state.rowData}
                  pagination
                  paginationPageSize={100}
                  rowSelection="single"
                  onSelectionChanged={this.onRowSelected} />
              </div>
            );
          })()}
        </div>
      </div>
      
    );
  }
}

const container =  withStyles(styles)(SourceConfigs);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(container)



