import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import history from '../history'
import user from './user';
import app from './app';
import sources from './sources';
import configurations from './configurations';

const rootReducer = combineReducers({
  user,
  app,
  sources,
  configurations,
  router: connectRouter(history),
});

export default rootReducer;
