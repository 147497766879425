import * as actions from '../actions/configurations';

const initialState = {
  sourceConfigurations: [],
  isFetchingConfigs: false,
  selectedConfig: null,
  isFetchingSelectedConfig: false,
  isFetchingRC: false,
};

export default function configurations(state = initialState, action) {
  switch (action.type) {
    case actions.FETCHING_SOURCE_CONFIGS:
      return {
        ...state,
        isFetchingConfigs: true,
      };
    case actions.RECEIVED_SOURCE_CONFIGS:
      return {
        ...state,
        isFetchingConfigs: false,
        sourceConfigurations: action.sourceConfigurations,
      };
    case actions.SELECT_SOURCE_CONFIG:
      return {
        ...state,
        selectedConfig: action.selectedConfig,
        isFetchingSelectedConfig: false,
      };
    case actions.FETCHING_SOURCE_CONFIG:
      return {
        ...state,
        isFetchingSelectedConfig: true,
      }
    case actions.SELECT_RUNTIME_GONFIG_GUID:
      return {
        ...state,
        isFetchingRC: true,
      }
    case actions.SELECT_RUNTIME_CONFIG:
      return {
        ...state,
        selectedRC: action.selectedRC,
        isFetchingRC: false,
      }
    default:
      return state;
  }
}
