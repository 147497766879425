import { fetchSources } from '../utils/api';
import { getSourceGuid } from '../selectors/router';
import jssdk from '../utils/jssdk';

export const FETCHING_COMMERCE_SOURCES = 'FETCHING_COMMERCE_SOURCES'
export const RECEIVED_COMMERCE_SOURCES = 'RECEIVED_COMMERCE_SOURCES'
export const SELECT_COMMERCE_SOURCE = 'SELECT_COMMERCE_SOURCE'
export const RUN_SOURCE = 'RUN_SOURCE'
export const SOURCE_EXECUTED = 'SOURCE_EXECUTED'

export const selectCommerceSource = (selectedSource) => ({ type: SELECT_COMMERCE_SOURCE, selectedSource })

export function getCommerceSources() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FETCHING_COMMERCE_SOURCES })
      const commerceSources = await fetchSources() 
      dispatch({ type: RECEIVED_COMMERCE_SOURCES, commerceSources })
      const state = getState();
      const selectedSourceGuidMatch = getSourceGuid(state);
      const selectedSourceGuid = !!selectedSourceGuidMatch && selectedSourceGuidMatch.params.sourceGuid;
      if (!selectedSourceGuid) return;

      const selectedSource = commerceSources.find(s => s.guid === selectedSourceGuid);
      if (!selectedSource) return;

      dispatch(selectCommerceSource(selectedSource))
 
    } catch (e) {
      console.error('Error fetching commerce sources', e)
      dispatch({ type: RECEIVED_COMMERCE_SOURCES, commerceSources: [] })
    }
  }
}

export function resolveProduct(sourceId, { product, webhook }) {
  return async dispatch => {
    try {
      dispatch({ type: RUN_SOURCE })
      const resolveProductResponse = await jssdk.commerce.resolveProduct(sourceId, product, webhook)
      dispatch({ type: SOURCE_EXECUTED, resolveProductResponse })
    } catch (e) {
      dispatch({ type: SOURCE_EXECUTED, resolveProductResponse: e })
      console.error('Error calling resolve product', e)
    }
  }
}

// export function getCommerceSource(sourceId) {
//   return async dispatch => {
//     try {
//       dispatch({ type: FETCHING_COMMERCE_SOURCE })
//       const selectedSource = await _fetchSource(sourceId) 
//       dispatch(selectCommerceSource(selectedSource))
//     } catch (e) {
//       console.error('Error fetching commerce sources', e)
//       dispatch({ type: RECEIVED_COMMERCE_SOURCES, commerceSources: [] })
//     }
//   }
// }