import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import { DRAWER_WIDTH } from '../utils/constants'
import { hot } from 'react-hot-loader';
import { autobind } from '../utils/objectUtils';
import { Route, Switch } from 'react-router';
import SourceSidebar from './SourceSidebar';
import ConfigSidebar from './ConfigSidebar';
import RCSidebar from './RCSidebar';

function mapStateToProps() {
  return {
  }
}

function mapDispatchToProps() {
  return {
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: 0,
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

class Sidebar extends React.PureComponent {

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    const {
      classes,
    } = this.props
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper, !this.props.isOpen && classes.drawerPaperClose),
        }}
        open={this.props.isOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={this.props.onClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Divider />
        <div style={{ position: 'relative', height: '100%' }}>
          <Switch>
            <Route path="/sources/:sourceGuid/configurations/:configGuid/runtimeConfig/:rcGuid" component={RCSidebar} />
            <Route path="/sources/:sourceGuid/configurations/:configGuid" component={ConfigSidebar} />
            <Route path="/sources/:sourceGuid" component={SourceSidebar} />
          </Switch>
        </div>
      </Drawer>
    );
  }
}

const componentWithStyles =  withStyles(styles)(Sidebar);

const container =  connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(componentWithStyles)

export default hot(module)(container);
