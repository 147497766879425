
import { setApiKey } from './user'

export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const SET_CHROMIUM_ENDPOINT = 'SET_CHROMIUM_ENDPOINT';
export const APP_LOADED_SAGA = 'APP_LOADED_SAGA';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const appLoadedSaga            = () => ({ type: APP_LOADED_SAGA })
export const setChromiumEndpoint      = (chromiumEndpoint) => ({ type: SET_CHROMIUM_ENDPOINT, chromiumEndpoint })
export const toggleSidebar            = (sidebarOpen) => ({ type: TOGGLE_SIDEBAR, sidebarOpen })


export function setEnvironment(environment) {
  return {
    type: SET_ENVIRONMENT,
    environment,
  };
}

export function userEnvironmentOverride({ apiKey, environment }) {
  return dispatch => {
    dispatch(setEnvironment(environment))
    dispatch(setApiKey(apiKey))
    dispatch(appLoadedSaga())
  }
}
