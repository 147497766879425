import jssdk from './jssdk';
import _get from 'lodash/get'
import {ObjectStoreQueryRequestBuilder} from "@import/js-sdk";

export function convertToArray(lsv) {
  const lines = lsv.split(/\n/);
  const attachments = [];

  for (let i=0; i < lines.length; i++) {
    if (/\S/.test(lines[i])) {
      attachments.push(lines[i].trim());
    }
  }

  return attachments;
}

export async function fetchSources() {
  const perPage = 4000
  let page = 1;

  let fullResults = [];
  let results = null;

  while (results == null || results.length >= perPage) {
    results = await jssdk.commerceSource.query(
        new ObjectStoreQueryRequestBuilder()
            .setPageLimit(perPage)
            .setPageNumber(page++)
            .setShowArchived(false)
            .build()
    );
    fullResults = [...fullResults, ...results];
  }

  fullResults = _.orderBy(fullResults, 'domain');

  return fullResults;
}

function getUndefinedOrExtractionAssociationAsync(sourceConfigurationId, index) {
  return jssdk.commerce.getAssociation({
    associationType: 'extraction', sourceConfigurationId, index,
  }).catch(err => {
    if (err.status == 404) {
      return undefined;
    }
    else {
      throw err;
    }
  });
}

export async function propertiesFromAssociations(source) {
  // read association
  const searchRC = getUndefinedOrExtractionAssociationAsync(source.guid, 0)
    .then(GetProductsFromSearchRC => {
      // replace property value with association if exits
      if (GetProductsFromSearchRC)
        source.properties.GetProductsFromSearchRC = GetProductsFromSearchRC.guid
    })
  // read association
  const urlRC = getUndefinedOrExtractionAssociationAsync(source.guid, 1)
    .then(GetProductFromUrlRC => {
      // replace property value with association if exists
      if (GetProductFromUrlRC)
        source.properties.GetProductFromUrlRC = GetProductFromUrlRC.guid
    })
  await Promise.allSettled([searchRC, urlRC])
  return source.properties
}

export async function fetchSourceConfigurations(commerceSourceId) {
  const perPage = 4000
  let page = 1;

  let fullResults = await jssdk.commerceConfiguration.query(
        new ObjectStoreQueryRequestBuilder()
            .setPageLimit(perPage)
            .setPageNumber(page++)
            .setShowArchived(false)
            .addEqFilter('commerceSourceId', commerceSourceId)
            .build()
    );


  await Promise.all(
      fullResults.map(async (r) => {
          r.properties = await propertiesFromAssociations(r);
      })
  );

  return fullResults;
}


export async function fetchRuntimeConfig(rcGuid) {
  try {
    const runtimeConfig = await jssdk.runtimeConfiguration.get(rcGuid)
    if (runtimeConfig.extractorId) {
      const extractor = await jssdk.extractor.storeService.get(runtimeConfig.extractorId)

      let inputs = []
      if (extractor.inputs) {
        const response = await jssdk.extractor.storeService.getAttachment(extractor.guid, 'inputs', extractor.inputs).then(response => response.text())
        inputs = !response ? [] : convertToArray(response).map(i => JSON.parse(i))
      }
      runtimeConfig.inputs = inputs
      runtimeConfig.extractor = extractor
    }
    return runtimeConfig
  } catch (e) {
    console.error('Error getting runtime config', e)
    throw e;
  }
}
