import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as SourceActions from '../actions/sources';
import { toggleSidebar } from '../actions/app';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router'



function mapStateToProps(state) {
  return {
    ...state.sources,
    sidebarOpen: state.app.sidebarOpen,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...SourceActions, toggleSidebar, push }, dispatch),
  }
}

const styles = theme => ({
  root: { 
    display: 'flex', 
    alignItems: 'flex-start', 
    justifyContent: 'center', 
    height: 'calc(100vh - 175px)', 
    width: '100%',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  input: {
    margin: theme.spacing.unit,
  },
  flexCenter: { display: 'flex', justifyContent: 'center', margin: theme.spacing.unit * 2 },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class Sources extends Component {
  static propTypes = {
    commerceSources: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    isFetchingSources: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      selectCommerceSource: PropTypes.func.isRequired,
      toggleSidebar: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }),
    sidebarOpen: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: 'Id', field: 'guid', width: 300 },
        { headerName: 'Domain', field: 'domain' },
      ],
      rowData: [],
    }
  }

  componentDidMount() {
    this.setUpGrid()
    if (this.props.match.params.sourceGuid) {
      this.props.actions.toggleSidebar(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.commerceSources.length !== this.props.commerceSources.length) {
      this.setUpGrid()
    }
    if (prevProps.sidebarOpen && !this.props.sidebarOpen && this.gridApi) {
      this.gridApi.deselectAll()
    }
  }

  setUpGrid() {
    this.setState({ rowData: this.props.commerceSources })
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  onRowSelected = () => {
    const selectedRow = this.gridApi.getSelectedRows()[0];
    if (!selectedRow) return;

    this.props.actions.selectCommerceSource(selectedRow)
    if (!this.props.sidebarOpen) this.props.actions.toggleSidebar(true);
    this.props.actions.push(`/sources/${selectedRow.guid}`)
  }

  render() {
    const { isFetchingSources, classes } = this.props;
    
    return (
      <div>
        <div className={classes.flexCenter} style={{ alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom>
                  Commerce Sources
          </Typography>
        </div>
        <div className={classes.root}>
        
          {(() => {
            if (isFetchingSources) {
              return (
                <div className={classes.loaderContainer}>
                  <CircularProgress className={classes.progress} />
                </div>
              
              );
            }
            return ( 
              <div 
                className="ag-theme-material"
                style={{ height: 'calc(100vh - 220px)',  width: '100%', maxHeight: '90%', maxWidth: 500 }} >
              
                <div className={classes.flexCenter}>
                  <Input
                    fullWidth
                    placeholder="Search"
                    className={classes.input}
                    onChange={(e) => {
                      if (this.gridApi) this.gridApi.setQuickFilter(e.target.value)
                    }}
                    inputProps={{
                      'aria-label': 'Search',
                    }}/>
                </div>
              
                <AgGridReact
                  enableSorting
                  enableFilter
                  enableColResize
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}
                  pagination
                  paginationPageSize={100}
                  rowSelection="single"
                  onSelectionChanged={this.onRowSelected} />
              </div>
            );
          })()}
        </div>
      </div>
      
    );
  }
}

const container =  withStyles(styles)(Sources);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(container)



