import * as actions from '../actions/sources';

const initialState = {
  commerceSources: [],
  isFetchingSources: false,
  selectedSource: null,
  isRunningSource: false,
};

export default function sources(state = initialState, action) {
  switch (action.type) {
    case actions.FETCHING_COMMERCE_SOURCES:
      return {
        ...state,
        isFetchingSources: true,
      };
    case actions.RECEIVED_COMMERCE_SOURCES:
      return {
        ...state,
        isFetchingSources: false,
        commerceSources: action.commerceSources,
      };
    case actions.SELECT_COMMERCE_SOURCE:
      return {
        ...state,
        selectedSource: action.selectedSource,
      };
    case actions.RUN_SOURCE:
      return {
        ...state,
        isRunningSource: true,
      };
    case actions.SOURCE_EXECUTED:
      return {
        ...state,
        isRunningSource: false,
        resolveProductResponse: action.resolveProductResponse || '',
      };
    default:
      return state;
  }
}
