import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ConfigActions from '../actions/configurations';
import { toggleSidebar } from '../actions/app';
import { push } from 'connected-react-router'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Button from '@material-ui/core/Button';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import { cloneDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import AceEditor from 'react-ace';
import { isJsonValid, autobind } from '../utils/objectUtils';
import PlayIcon from '@material-ui/icons/PlayArrow'
import { endpoints } from '@import/frontend-utils';
import * as jssdk from '../utils/jssdk';
import { getDebuggerUrl } from '../utils/misc';
import { debounce } from 'lodash';



function mapStateToProps(state) {
  return {
    ...state.configurations,
    selectedSource: state.sources.selectedSource,
    sidebarOpen: state.app.sidebarOpen,
    environment: state.app.environment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ ...ConfigActions, push, toggleSidebar }, dispatch),
  }
}


const styles = (theme) => ({
  root: {
    // display: 'flex',
    margin: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  loaderContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class RCSidebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      push: PropTypes.func,
      toggleSidebar: PropTypes.func,
      updateConfigStatus: PropTypes.func,
      selectRCGuid: PropTypes.func.isRequired,
    }),
    selectedConfig: PropTypes.object,
    selectedRC: PropTypes.object,
    isFetchingRC: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    selectedSource: PropTypes.object,
    environment: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      isRunPanelOpen: false,
      defaultInput: '',
      gettingLatestCrawlRun: true,
    }
    this.getLatestCrawlRun = debounce(this.getLatestCrawlRun, 500)
    autobind(this);
  }

  componentDidMount() {
    this.props.actions.selectRCGuid(this.props.match.params.rcGuid)
    if (this.props.selectedRC) {
      this.setRuntimeConfig()
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedRC } = this.props;
    if (prevProps.selectedRC !== selectedRC) {
      this.setRuntimeConfig()
      this.getLatestCrawlRun();
    }
  }

  async getLatestCrawlRun() {
    const { selectedRC } = this.props;
    const { guid } = selectedRC.extractor;
    let latestCrawlRunId;
    try {
      const resp = await jssdk.default.extractor.getCrawlRuns(guid);
      const crawlRuns = resp.runs;
      if (crawlRuns && crawlRuns.length) {
        latestCrawlRunId = crawlRuns[0].guid;
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({ latestCrawlRunId });
  }

  setRuntimeConfig() {
    const { selectedRC } = this.props;
    const { inputs } = selectedRC;
    let defaultInput = JSON.stringify('{}', null, 2)
    if (Array.isArray(inputs) && inputs[0]) {
      defaultInput = JSON.stringify(inputs[0], null, 2)
    }
    this.setState({ defaultInput })
  }

  onSaveClick = () => {
    console.log('fuck')
  }

  isSaveDisabled() {
    return true;
  }

  getRuntimeConfigName(guid) {
    let name = ''
    Object.entries(this.props.selectedConfig.properties).forEach(([key, value]) => {
      if (value === guid) name = key;
    })
    return name;
  }

  onBackClick = () => {
    const { selectedConfig, selectedSource } = this.props;
    this.props.actions.push(`/sources/${selectedSource.guid}/configurations/${selectedConfig.guid}`)
  }

  isPlayDisabled() {
    return !isJsonValid(this.state.defaultInput)
  }

  openThunder = () => {
    const { selectedRC } = this.props;
    const { defaultInput } = this.state;
    window.open(`https://thunder.${endpoints.rootDomain}?runtimeConfig=${selectedRC.guid}&input=${encodeURIComponent(defaultInput)}`, '_blank')
  }

  render() {
    const { classes, isFetchingRC, selectedRC, selectedConfig } = this.props;
    const { isRunPanelOpen, defaultInput } = this.state;
    if (!selectedRC || !selectedConfig || isFetchingRC) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }


    const runPanel = (
      <React.Fragment>
        <AceEditor
          style={{ height: 300, width: 468 }}
          mode="json"
          theme="terminal"
          name="runPrompt"
          key="runPrompt"
          onChange={(defaultInput) => this.setState({ defaultInput })}
          editorProps={{ $blockScrolling: Infinity }}
          fontSize="12px"
          highlightActiveLine
          showGutter={false}
          value={(defaultInput || '').toString()}/>
        <div style={{ display: 'flex' }}>
          <Button variant="outlined" 
            className={classes.button} 
            onClick={() => this.setState({ isRunPanelOpen: false })}>
                    Cancel
          </Button>
          <Button variant="outlined" 
            disabled={this.isPlayDisabled()} 
            className={classes.button} 
            onClick={this.openThunder}>
                   Play <PlayIcon className={classes.rightIcon} />
          </Button>
         
        </div>
      </React.Fragment>
    )
    return (
      <div className={classes.root}>
        <div className={classes.backButton}>
          <IconButton onClick={this.onBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Typography variant="h5" gutterBottom>
          {this.getRuntimeConfigName(selectedRC.guid)}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {selectedRC.guid}
        </Typography>
        <div>
          {/* <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="age-label-placeholder">
            Status
            </InputLabel>
            <Select
              value={selectedConfig.status}
              onChange={this.handleStatusChange}
              input={<Input name="status" id="satus-label-placeholder" />}
              displayEmpty
              name="status"
              className={classes.selectEmpty}
            >
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="PROVISIONING">PROVISIONING</MenuItem>
              <MenuItem value="IN_REVIEW">IN_REVIEW</MenuItem>
              <MenuItem value="ERROR">ERROR</MenuItem>
              <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
            </Select>
          </FormControl>
          <Button variant="outlined" disabled={this.isSaveDisabled()} className={classes.button} onClick={this.onSaveClick}>
          Save
          </Button> */}
          {this.state.latestCrawlRunId &&
            <Button
              variant="outlined"
              href = {getDebuggerUrl(this.state.latestCrawlRunId, this.props.environment)}
              target="_blank"
            > Debug Latest Crawl Run </Button>
          }
          {selectedRC.extractor && (
            <a href={`https://app.${this.props.environment}/dash/extractors/${selectedRC.extractor.guid}/history`}>
              <List className={classes.list}>
                <ListItem onClick={() => {}}>
                  <ListItemText
                    primary="Extractor"
                    secondary={
                      <React.Fragment>
                        <Typography component="span" className={classes.inline} color="textPrimary">
                          {selectedRC.extractor.guid}
                        </Typography>
                        <span style={{ overflow: 'hidden', width: '100%', display: 'block' }}>{selectedRC.extractor.name}</span>
                        
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </a>
          )}
          
        </div>
        <div>
          {isRunPanelOpen ? runPanel : (
            <Button variant="outlined" 
              className={classes.button} 
              onClick={() => this.setState({ isRunPanelOpen: true })}>
                   Play <PlayIcon className={classes.rightIcon} />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const componentWithStyles =  withStyles(styles)(RCSidebar);

export default connect(mapStateToProps, mapDispatchToProps, (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps, 
    ...ownProps,
    actions: dispatchProps,
  }
})(componentWithStyles)
