import { takeEvery, put } from 'redux-saga/effects';
import * as SourceActions from '../actions/sources';
import { getConfigsForSource } from '../actions/configurations';

function *sourceSelected({ selectedSource }) {
  yield put(getConfigsForSource(selectedSource.guid))
}

export default [
  takeEvery(SourceActions.SELECT_COMMERCE_SOURCE, sourceSelected),
]