
import React from 'react';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import App from './components/App';
import history from './history';

export default () =>  (
  <ConnectedRouter history={history}>
    <div>
      <Route path="/" component={App}/>
    </div>
  </ConnectedRouter>
);