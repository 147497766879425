import * as actions from '../actions/app';
import { endpoints } from '@import/frontend-utils';
import { createSdk } from '../utils/jssdk'


const initialState = {
  environment: endpoints.rootDomain,
  chromiumEndpoint: `wss://chromium.${endpoints.rootDomain}`,
  sidebarOpen: false,
};

export default function (state = initialState, action = { type: 'null' }) {
  switch (action.type) {
    case actions.SET_ENVIRONMENT:
      const { environment } = action;
      createSdk({ environment })
      endpoints.endpointsRootDomain = environment;
      const chromiumEndpoint = `wss://chromium.${environment}/websocket`
      return { ...state, environment, chromiumEndpoint };
    case actions.SET_CHROMIUM_ENDPOINT:
      return { ...state, chromiumEndpoint: `ws://${action.chromiumEndpoint}/websocket` }
    case actions.TOGGLE_SIDEBAR:
      return { ...state, sidebarOpen: action.sidebarOpen }
    default:
      return state;
  }
}
