import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import TrashIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { setEnvironment, userEnvironmentOverride, setChromiumEndpoint } from '../actions/app';
import { ENVIRONMENTS } from '../utils/constants';

const LOCAL_STORAGE_KEY = 'merchant-ui-environments';

const getPrettyName = (environment) => {
  switch (environment) {
    case 'import.io':
      return 'Production';
    case 'demo-owl.com':
      return 'Demo';
    case 'staging-owl.com':
      return 'Staging';
    default: 
      return '';
  }
}

const getUglyName = (hostname) => {
  switch (hostname) {
    case 'Production':
      return 'import.io';
    case 'Demo':
      return 'demo-owl.com';
    case 'Staging':
      return 'staging-owl.com';
    default: 
      return '';
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
});

class EnvironmentPicker extends Component {
  static propTypes = {
    environment: PropTypes.string.isRequired,
    userEnvironmentOverride: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    userApiKey: PropTypes.string,
    chromiumEndpoint: PropTypes.string.isRequired,
    setChromiumEndpoint: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props) 
    this.state = {
      environmentConfigs: {},
      isAddNewDialogOpen: false,
      isSelectDialogOpen: false,
      newApiKey: '',
      newApiKeyName: '',
      selectedEnvironment: '',
      selectedChromiumEndpoint: '', 
      chromiumPort: 3000,
    }
  }

  componentDidMount() {
    const environmentConfigs = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')
    this.setUpEnvironmentConfigs(environmentConfigs)

    if (this.props.chromiumEndpoint) {
      this.setState({ selectedChromiumEndpoint: this.props.chromiumEndpoint })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chromiumEndpoint !== this.props.chromiumEndpoint) {
      this.setState({ selectedChromiumEndpoint: this.props.chromiumEndpoint })
    }
  }

  setUpEnvironmentConfigs(environmentConfigs) {
    // Add the environment name to each api key so we can reference it later
    Object.entries(environmentConfigs).forEach(([ key, value ]) => {
      if (Array.isArray(value)) {
        const newArr = value.map((apiKey) => {
          apiKey.environment = key;
          return apiKey
        })
        environmentConfigs[key] = newArr;
      } else {
        environmentConfigs[key] = []
      }
      
    })
    this.setState({ environmentConfigs })
  }

  onEnvironmentChange(environment) {
    // Open the settings modal
    if (environment === 'Manage') return this.setState({ isSelectDialogOpen: true })

    // Otherwise user selected a environment
    const prettyEnvName = getPrettyName(environment);
    const envApiKeys = this.state.environmentConfigs[prettyEnvName]
    // If they already have api keys for the environment show that dialog, otherwise show the add new api key dialog
    if (envApiKeys && envApiKeys.length) this.setState({ selectedEnvironment: prettyEnvName, isSelectDialogOpen: true })
    else this.setState({ isAddNewDialogOpen: true, selectedEnvironment: prettyEnvName, switchingToRootDomain: environment })
  }

  handleCloseAddNewDialog = () => {
    this.setState({ isAddNewDialogOpen: false, newApiKey: '', selectedEnvironment: '', newApiKeyName: '', switchingToRootDomain: '' })
  }

  handleCloseSelectDialog = () => {
    this.setState({ isSelectDialogOpen: false, selectedEnvironment: '' })
  }

  setEnvironment = ({ environment, key: apiKey = null }) => {
    this.props.userEnvironmentOverride({ environment: getUglyName(environment), apiKey })
    this.handleCloseAddNewDialog()
    this.handleCloseSelectDialog()
  }

  onSubmitNewApiKey = () => {
    const { selectedEnvironment, newApiKey, newApiKeyName, environmentConfigs } = this.state;
    // Add to env configs in state
    environmentConfigs[selectedEnvironment] = environmentConfigs[selectedEnvironment] || []
    environmentConfigs[selectedEnvironment].push({ name: newApiKeyName, key: newApiKey })
    this.setUpEnvironmentConfigs(environmentConfigs)
    // Set in local storage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(environmentConfigs))
    // Make the change
    this.setEnvironment({ environment: selectedEnvironment, key: newApiKey })
    this.handleCloseAddNewDialog()
  }

  getOwl(rootDomain) {
    switch (rootDomain) {
      case 'Production':
        return '/Needy.png';
      case 'Demo':
        return '/Pointy.png';
      case 'Staging':
        return './Smart.png';
      default:
        return '';
    }
  }

  deleteApiKey(environment, index) {
    const { environmentConfigs } = this.state;
    environmentConfigs[environment].splice(index, 1)
    this.setUpEnvironmentConfigs(environmentConfigs)
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(environmentConfigs))
  }

  render() {
    const { classes, userApiKey } = this.props;
    const { 
      selectedEnvironment, 
      switchingToRootDomain, 
      environmentConfigs, 
      isAddNewDialogOpen, 
      isSelectDialogOpen, 
      selectedChromiumEndpoint, 
      chromiumPort, 
    } = this.state;
    const selectedEnvApiKeys = selectedEnvironment && environmentConfigs[selectedEnvironment];
    let apiKeys = []
    if (selectedEnvApiKeys) {
      apiKeys = environmentConfigs[selectedEnvironment]
    } else if (environmentConfigs) {
      Object.values(environmentConfigs).forEach(val => {
        if (val && Array.isArray(val)) {
          apiKeys = [...apiKeys, ...val]
        }
      })
    }
    return (
      <form style={{
        marginLeft: 40,
        minWidth: 200,
      }}>
        {/* Dropdown */}
        <FormControl>
          <InputLabel style={{ color: 'white' }} htmlFor="environment-selector">Environment</InputLabel>
          <Select
            style={{ color: 'white' }}
            onChange={(e) => this.onEnvironmentChange(e.target.value)}
            value={this.props.environment}
            input={<FilledInput name="environment-selector" id="environment-selector" />}
          >
            <MenuItem value={ENVIRONMENTS.PROD}>Production</MenuItem>
            <MenuItem value={ENVIRONMENTS.DEMO}>Demo</MenuItem>
            <MenuItem value={ENVIRONMENTS.STAGE}>Staging</MenuItem>
            <MenuItem value="Manage">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Manage"/>
            </MenuItem>
          </Select>
        </FormControl>

        {/* Add new dialog */}
        <Dialog
          open={isAddNewDialogOpen}
          onClose={this.handleCloseAddNewDialog}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add API Key</DialogTitle>
          <DialogContent>
            {switchingToRootDomain ? (
              <DialogContentText>
                {`You don't have an API key set for ${ selectedEnvironment || 'this environment'}. Please add one below`}
              </DialogContentText>
            ):
              (
                <React.Fragment>
                  <DialogContentText>
                    Add a new API Key for:
                  </DialogContentText>
                  <FormControl>
                    <Select
                      onChange={(e) => this.setState({ selectedEnvironment: e.target.value })}
                      value={selectedEnvironment || 'Production'}
                      input={<FilledInput name="environment-selector2" id="environment-selector2" />}
                    >
                      <MenuItem value="Production">Production</MenuItem>
                      <MenuItem value="Demo">Demo</MenuItem>
                      <MenuItem value="Staging">Staging</MenuItem>
                    </Select>
                  </FormControl>
                </React.Fragment>
              )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              onChange={(e) => this.setState({ newApiKeyName: e.target.value })}/>
            <TextField
              margin="dense"
              id="apiKey"
              label={`${selectedEnvironment} Api Key`}
              type="text"
              fullWidth
              onChange={(e) => this.setState({ newApiKey: e.target.value })}/>
          </DialogContent>
          <DialogActions>
            {!switchingToRootDomain || (switchingToRootDomain === 'import.io' && (!window.location.hostname.includes('import.io'))) ? (
              <Button onClick={this.handleCloseAddNewDialog} color="primary">
              Cancel
              </Button>
            ) : (
              <Button onClick={() => this.setEnvironment({ environment: selectedEnvironment })} color="primary">
              It's cool I'm already logged into {selectedEnvironment}
              </Button>
            )}
            <Button onClick={this.onSubmitNewApiKey} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Manage dialog */}
        <Dialog
          open={isSelectDialogOpen}
          onClose={this.handleCloseSelectDialog}
          aria-labelledby="form-dialog-title"
          fullWidth>

          {/* Chromium endpoint setter */}
          {!selectedEnvApiKeys && (
            <React.Fragment>
              <DialogTitle id="chromium-title">Chromium Endpoint</DialogTitle>
              <DialogContent>
                <FormControl>
                  <Select
                    onChange={(e) => this.setState({ selectedChromiumEndpoint: e.target.value })}
                    value={selectedChromiumEndpoint}
                    input={<FilledInput name="chromium" id="chromium" />}>
                    <MenuItem value={this.props.chromiumEndpoint}>{this.props.chromiumEndpoint}</MenuItem>
                    <MenuItem value="localhost">localhost</MenuItem>
                  </Select>
                  {selectedChromiumEndpoint.includes('localhost') && (
                    <TextField
                      margin="dense"
                      id="port"
                      label="Port"
                      type="text"
                      fullWidth
                      onChange={(e) => this.setState({ chromiumPort: e.target.value })}/>
                  )}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button 
                  onClick={() => this.props.setChromiumEndpoint(`${selectedChromiumEndpoint}:${chromiumPort}`)} 
                  color="primary" 
                  disabled={selectedChromiumEndpoint === this.props.chromiumEndpoint}>
              Apply
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
          
          {/* List the api keys */}
          <DialogTitle id="form-dialog-title">{selectedEnvApiKeys ? selectedEnvironment : 'Manage'} API Keys</DialogTitle>
          <DialogContent>
            <List className={classes.root}>
              {apiKeys.map((config, idx) => (
                <div style={{ maxWidth: 500, display: 'flex', width: 500, justifyContent: 'space-between' }} key={idx}>
                  <ListItem button onClick={() => this.setEnvironment(config)} selected={userApiKey === config.key}>
                    <ListItemAvatar>
                      <Avatar src={this.getOwl(config.environment)} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={selectedEnvApiKeys ? undefined : config.environment }
                      secondary={
                        <React.Fragment>
                          <Typography component="span" color="textPrimary">
                            {config.name}
                          </Typography>
                          <span style={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis', display: 'block' }}>{config.key}</span>
                        </React.Fragment>
                      }/>
                  </ListItem>
                  <IconButton
                    color="inherit"
                    onClick={() => this.deleteApiKey(config.environment, idx)}>
                    <TrashIcon />
                  </IconButton>
                </div>
              ))}
              
              {/* Ability for user to select each of their logged in users */}
              {(selectedEnvironment === 'Production' || !selectedEnvironment) && (
                <ListItem button onClick={() => this.setEnvironment({ environment: 'Production' })}>
                  <ListItemAvatar>
                    <Avatar src={this.getOwl('Production')} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Production Logged In User"/>
                </ListItem>
              )}
              {(selectedEnvironment === 'Demo' || !selectedEnvironment )&& (
                <ListItem button onClick={() => this.setEnvironment({ environment: 'Demo' })}>
                  <ListItemAvatar>
                    <Avatar src={this.getOwl('Demo')} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Demo Logged In User"/>
                </ListItem>
              )}
              {(selectedEnvironment === 'Staging' || !selectedEnvironment) && (
                <ListItem button onClick={() => this.setEnvironment({ environment: 'Staging' })}>
                  <ListItemAvatar>
                    <Avatar src={this.getOwl('Staging')} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Staging Logged In User"/>
                </ListItem>
              )}
            </List>
            <DialogActions>
              <Button onClick={() => {
                this.setState({ isAddNewDialogOpen: true, isSelectDialogOpen: false })
              }} color="primary">
              Add New
              </Button>
            </DialogActions>
          </DialogContent>
          
          {/* Generic close button */}
          <DialogActions>
            <Button onClick={this.handleCloseSelectDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
}


function mapStateToProps(state) {
  return {
    environment: state.app.environment,
    userApiKey: state.user.apiKey,
    chromiumEndpoint: state.app.chromiumEndpoint,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setEnvironment: (environment) => dispatch(setEnvironment(environment)),
    userEnvironmentOverride: (args) => dispatch(userEnvironmentOverride(args)),
    setChromiumEndpoint: (args) => dispatch(setChromiumEndpoint(args)),
  };
}

const container = withStyles(styles)(EnvironmentPicker)

export default connect(mapStateToProps, mapDispatchToProps)(container);